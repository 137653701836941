import { Component, Vue } from "vue-property-decorator";
import VueCookies from 'vue-cookies'
import { GET_PROFILE_AGENT,CREATE_AGENT,GET_ROYALTY_SETTING_BY_ID } from "../../../../api/account"
import vSelect from 'vue-select';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'
import moment from 'moment';

Vue.use(VModal, { componentName: 'modal' })
Vue.use(VueCookies)

import 'vue-select/dist/vue-select.css';

@Component({
    components: {
        vSelect
    },
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        date(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        sCurrency(value: any) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
})

default class Agent extends Vue {
    $axios: any;
    private selected = "";
    private navShow = true
    private role = ""
    public updateDate = "";
    private profile: any = {}
    private myRate = 0
    private ipUser = "0.0.0.0"

    private cookieData: any = Vue.$cookies.get("login")
    private options: { text: string; value: string }[] = []
    private displayShow = true
    private dataAgent: any = {
        username: '',
        name: '',
        email: '',
        phone: '',
        referrer: '',
        remark: '',
        royaltySetting: [],
    }
    private password = {
        default: false,
        confirm: false
    }
    private limitList = [
        {name: 'Limit A', min: 40, max: 200 , value: 1, check : true},
        {name: 'Limit B', min: 200, max: 1000 , value: 2, check : false},
        {name: 'Limit C', min: 2000, max: 10000, value: 3, check : false},
        {name: 'Limit D', min: 10000, max: 50000 , value: 4, check : false},
        {name: 'Limit E', min: 50000, max: 100000 , value: 5, check : false}
    ]
    private royaltySetting: any = []
    private typeBusiness = ''
    private typeAgent = ''
    private typeAgentAPI = ''
    private model: Model;
    private numberArray: any = []
    private currencyArray: any = []
    constructor() {
        super();
        this.model = new Model();
    }
    async mounted() {
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        await this.getRoyaltySetting() // find my rate
        await this.getProfile(this.cookieData.realUsername)
        await this.initData()
        await this.setRate()
        this.addRouteName()
        loader.hide()
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public async initData() {
        const currencyUse = process.env.VUE_APP_CURRENCY_USE
        this.currencyArray.push({label:`${currencyUse}`, value:`${currencyUse}`});
        this.dataAgent['parentName'] = this.cookieData.realUsername
        this.dataAgent['typeBusiness'] = this.typeBusiness
        this.dataAgent['typeAgent'] = this.typeAgent
        this.dataAgent['typeAgentAPI'] = this.typeAgentAPI
        if (this.role == 'Master Agent') {
        this.dataAgent['currency'] = this.currencyArray[0]
        }
    }

    public async setRate() {
        for(const value of this.royaltySetting.products)
        {
            if(value.productId == 'POKER')
                {
                    this.myRate  = value.rate[process.env.VUE_APP_CURRENCY_USE]
                }
        }

        for (let i = 0; i <= this.myRate; i ++) {
            this.numberArray.push({label:`${i}%`, value:`${i}`});
        }
        this.dataAgent['myPercentage'] = this.numberArray[this.myRate]
        this.dataAgent['yourPercentage'] = this.numberArray[0]
    }

    public async switchPercentage(event,myPercentage,yourPercentage) {
        if(event == 'myPercentage')
        {
            this.dataAgent['myPercentage'] = myPercentage
            this.dataAgent['yourPercentage'] = this.numberArray[this.myRate - myPercentage.value]
        } else
        {
            this.dataAgent['myPercentage'] = this.numberArray[this.myRate - yourPercentage.value]
            this.dataAgent['yourPercentage'] = yourPercentage
        }
    }

    public async getRoyaltySetting() {
        this.royaltySetting = await GET_ROYALTY_SETTING_BY_ID(this.cookieData.realUsername)
        this.royaltySetting = this.royaltySetting[0].royaltySetting
    }

    public toggleShowPassword(name: string) {
        if (name == 'default') {
            this.password.default = !this.password.default;
        }
        if (name == 'confirm') {
            this.password.confirm = !this.password.confirm;
        }
    }

    private async getProfile(username: string) {
        const result = await GET_PROFILE_AGENT(username)
        this.role = this.cookieData.roles[0]
        this.profile = result[0]

        for(const value of this.royaltySetting.products)
        {
            if(value.productId == 'POKER')
            {
                this.dataAgent['limit'] = value.limit
                this.dataAgent['myLimit'] = value.limit
                this.limitList[value.limit - 1].check = true
            }
        }

        this.typeBusiness  = this.royaltySetting.typeBusiness
            if (this.role == 'Company') {
                this.typeBusiness = 'B2B'
                this.dataAgent['childRole'] = 'Shareholder'
            } else
            {
                this.dataAgent['childRole'] = 'Agent'
                if(this.typeBusiness == 'B2B') {
                    this.typeAgent = 'API'
                    this.typeAgentAPI = 'Seamless'
                } else
                {
                    this.dataAgent['childRole'] = 'Master Agent'
                    this.typeAgent = 'Master Agent'
                }
            }
        this.dataAgent['parentId'] = result[0].username
    }

    public onlyNumber(event: any) {
        const keyCode = (event.keyCode ? event.keyCode : event.which);
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
            event.preventDefault();
        }
    }

    public clsAlphaNoOnly(event: any) {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public clsAlphaTHNoOnly(event: any) {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[ก-๏a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public formatNumber(value) {
        return value.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }


    public changeInputValue(key: string, value: string) {
        this.displayShow = false
        this.dataAgent[key] = value
        if(key == 'myPercentage' || key == 'yourPercentage')
        {
            this.switchPercentage(key,this.dataAgent['myPercentage'],this.dataAgent['yourPercentage'])
        }


        if(key == 'creditLimit')
        {
            this.dataAgent['creditLimit'] = this.formatNumber(this.dataAgent['creditLimit'])
        }

        if(key == 'typeAgent') 
        {
            if(value == 'API')
            {
                this.dataAgent['childRole'] = 'Agent'
                this.dataAgent['typeAgentAPI'] = 'Seamless'
            } else
            {
                this.dataAgent['childRole'] = 'Master Agent'
                this.dataAgent['typeAgentAPI'] = ''
            }
        }

        this.displayShow = true
    }


    public sendForm(event: any) {
        event.preventDefault();
        this.sendCreateAgent()
    }

    public async sendCreateAgent() {
        this.dataAgent['creditLimit'] = parseFloat(this.dataAgent['creditLimit'].replace(/,/g, ''))

        if (this.dataAgent.password.length < 6) {
            this.$swal({
                text: this.$t('MANAGEMENT.PASSWORD_CHECK').toString(),
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            this.dataAgent['creditLimit'] = this.formatNumber(this.dataAgent['creditLimit'])
            return;
        }

        if (this.dataAgent.password != this.dataAgent.confirmPassword) {
            this.$swal({
                text: this.$t('MANAGEMENT.PASSWORD_BE_SAME').toString(),
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            this.dataAgent['creditLimit'] = this.formatNumber(this.dataAgent['creditLimit'])
            return;
        }
        
        this.$swal({
            title: `${this.$t('MANAGEMENT.WANT_ADD_DATA').toString()}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('CONFIRM').toString()}</span>`,
            cancelButtonColor: '#FF0000',
            cancelButtonText: `<span style='color: white;'>${this.$t('CANCEL').toString()}</span>`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try
                {
                const resultCreateAgent = await CREATE_AGENT(this.dataAgent)
                if (resultCreateAgent.success) {
                    if(this.role == 'Company')
                    {
                        this.$swal({
                            text: `${this.$t('MANAGEMENT.ADD_SHAREHOLDER_SUCCESS').toString()}`,
                            icon: "success",
                            timer: 3000,
                            showConfirmButton: false,
                            timerProgressBar: true,
                            onClose: () => {
                                window.location.replace('/agent/agent-member-list');
                            }
                        });
                    } else
                    {
                        this.$swal({
                            text: `${this.$t('MANAGEMENT.ADD_AGENT_SUCCESS').toString()}`,
                            icon: "success",
                            timer: 3000,
                            showConfirmButton: false,
                            timerProgressBar: true,
                            onClose: () => {
                                window.location.replace('/agent/agent-member-list');
                            }
                        });
                    }
                } else {
                    this.dataAgent['creditLimit'] = this.formatNumber(this.dataAgent['creditLimit'])
                    if (resultCreateAgent.error.message.search("duplicate key") != -1) {
                        this.$swal({
                            text: `${this.$t('MANAGEMENT.NOT_USE_USERNAME').toString()}`,
                            icon: "error",
                            confirmButtonColor: '#CFA137',
                            confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                        });
                    } else
                    {
                        this.$swal({
                            text: resultCreateAgent.error.message,
                            icon: "error",
                            confirmButtonColor: '#CFA137',
                            confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                        });
                    }
                }
                }catch(err)
                {
                    this.dataAgent['creditLimit'] = this.formatNumber(this.dataAgent['creditLimit'])
                }
            }
            else
            {
                this.dataAgent['creditLimit'] = this.formatNumber(this.dataAgent['creditLimit'])
            }
        })
        
    }


    
}
export default Agent