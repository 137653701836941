import { Component, Vue } from "vue-property-decorator";
import VueCookies from 'vue-cookies'
import AgentComponent from '../Component/Agent.component.vue';
Vue.use(VueCookies)

@Component({
    components: {
        AgentComponent
    }
})

default class AddAgent extends Vue {

    private role = this.$store.state.profile.roles[0] 
}
export default AddAgent