




















































































































































































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import AgentComponent from "./Agent.component";

@Component
export default class Agent extends Mixins(AgentComponent) {}

